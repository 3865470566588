<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        The reaction below is <b>exothermic</b>. Answer each of the multiple choice questions below:
      </p>

      <p class="mb-5 pl-12">
        <number-value :value="equivsA" />
        <chemical-latex content="A(aq) +" />
        <number-value :value="equivsB" />
        <chemical-latex content="B(aq) <=>" />
        <number-value :value="equivsC" />
        <chemical-latex content="C(aq) +" />
        <chemical-latex content="D(aq)" />
      </p>

      <p class="mb-0">
        a) Which direction will the equilibrium shift if
        <chemical-latex :content="compoundChange1" />
        is <b>decreased</b>?
      </p>

      <v-radio-group v-model="inputs.input1" class="pl-6 mt-1 mb-2" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.condition" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        b) Which direction will the equilibrium shift if compound
        <chemical-latex content="X" />
        is added to the system which causes
        <chemical-latex :content="compoundChange2" />
        to precipitate?
      </p>

      <v-radio-group v-model="inputs.input2" class="pl-8 mb-2 mt-1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.condition" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        c) Which direction will the equilibrium shift if the temperature is
        <b>{{ changeInT }}</b
        >?
      </p>

      <v-radio-group v-model="inputs.input3" class="pl-8 mb-2 mt-1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.condition" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-0">
        d) Which direction will the equilibrium shift if the solution is
        <b>diluted</b>?
      </p>

      <v-radio-group v-model="inputs.input4" class="pl-8 mt-1 mb-n4" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.condition" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question175',
  components: {ChemicalLatex, StembleLatex, NumberValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options: [
        {condition: '$\\text{Shift left (form more reactants)}$', value: '1'},
        {condition: '$\\text{Shift right (form more products)}$', value: '2'},
        {condition: '$\\text{No change}$', value: '3'},
        {
          condition: '$\\text{Not enough information to determine}$',
          value: '4',
        },
      ],
    };
  },
  computed: {
    RorP1() {
      return this.taskState.getValueBySymbol('RorP1').content;
    },
    RorP2() {
      return this.taskState.getValueBySymbol('RorP2').content;
    },
    Tdirection() {
      return this.taskState.getValueBySymbol('Tdirection').content;
    },
    compoundChange1() {
      if (this.RorP1.value === 1) {
        return '[A]';
      } else if (this.RorP1.value === 2) {
        return '[C]';
      }
    },
    compoundChange2() {
      if (this.RorP2.value === 1) {
        return 'B';
      } else if (this.RorP2.value === 2) {
        return 'D';
      }
    },
    changeInT() {
      if (this.Tdirection.value === 1) {
        return 'increased';
      } else if (this.Tdirection.value === 2) {
        return 'decreased';
      }
    },
    equivsA() {
      return this.taskState.getValueBySymbol('equivsA').content;
    },
    equivsB() {
      return this.taskState.getValueBySymbol('equivsB').content;
    },
    equivsC() {
      return this.taskState.getValueBySymbol('equivsC').content;
    },
  },
};
</script>
